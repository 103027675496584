<template>
    <a-button class="primaryBtn">{{ $t("global.exportToExcel") }}</a-button>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
 
  setup() {
    return {

    };
  },
});
</script>
